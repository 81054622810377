<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-text-field label="* Senha Atual"
                    dense
                    v-model="value.senhaAtual"
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 100)
                  ]"/>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field label="* Nova Senha"
                    dense
                    v-model="value.senha"
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 6),
                      v => this.$validators.string.lessThanOrEquals(v, 20),
                  ]"/>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field label=" * Confirmar Nova Senha"
                    dense
                    v-model="value.senha2"
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 6),
                      v => this.$validators.string.lessThanOrEquals(v, 20),
                      v => this.$validators.string.camposIguais(value.senha, value.senha2),
                  ]"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AlterarSenhaForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>